define(['app'], function (app) {
 
  const parcelLabWidget = function () {

      const component = {};

      const _init = function (element) {
          component.element = element;
          _loadScript()
          return component;
      };
      
      const _loadScript = function () {

          function plTrackAndTraceStart() {
            var props = {};
            props[siteObj.subsiteCode] = {
                searchOrder: siteObj.props.parcelLabWidget.searchOrder,
                zip: siteObj.props.parcelLabWidget.zip,
                searchBtn: siteObj.props.parcelLabWidget.search
            };

            var options = {
                userId: component.element.getAttribute("data-parcellab-user-id"),
                show_searchForm: component.element.getAttribute("data-show-search-form"),
                show_zipCodeInput: component.element.getAttribute("data-show-zipcode-input"),
                lang: siteObj.subsiteCode,
                buttonBackground: 'inherit',
                buttonColor: 'inherit'
            };

            if (siteObj.config.enableParcelLabCustomTranslations) {
                options.customTranslations = props;
            }

            window.parcelLabTrackAndTrace.initialize(options);
            
            var linkTag = document.createElement('link');
            linkTag.rel = 'stylesheet';
            linkTag.href = 'https://cdn.parcellab.com/css/v5/main.min.css';
            document.getElementsByTagName('head')[0].appendChild(linkTag);
            
            var overwriteStyles = document.createElement('link');
            overwriteStyles.rel = 'stylesheet';
            overwriteStyles.href = 'https://cdn.parcellab.com/img/c/thehutgroup/thg_overwrite.css';
            document.getElementsByTagName('head')[0].appendChild(overwriteStyles);
          }

          var script = document.createElement('script');
          script.async = true;
          script.src = 'https://cdn.parcellab.com/js/v5/main.min.js';
          (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
          script.onload = plTrackAndTraceStart;
          
      };

      component.init = _init;
      return component;
  };

  return parcelLabWidget;
});
